import httpCommon from '@/plugins/http-common'

export default {
  state: {
    devices: {},
    users: {},
    displays: {},
  },
  getters: {
    users: state => state.users,
    getUserByID: function (state, id) {
      return state.users.find(obj => {
        return obj._id === id
      })
    },

    devices: state => state.devices,
    getDeviceByID: function (state) {
      return (id) => {
        return state.devices.find(obj => obj._id === id)
      }
    },

    displays: state => state.displays,
    getDisplaysByID: (state, id) => state.displays.find(obj => {
      return obj._id === id
    }),

  },
  mutations: {
    SET_USERS (state, payload) {
      state.users = payload
    },

    SET_LOCKERS (state, payload) {
      state.devices = payload
    },
    SET_DISPLAYS (state, payload) {
      state.displays = payload
    },

  },
  actions: {
    async getUsers ({ commit }) {
      return httpCommon.get('data/users/').then(response => {
        commit('SET_USERS', response.data)
      })
    },
    async getDevices ({ commit }) {
      return httpCommon.get('data/devices/').then(response => {
        commit('SET_LOCKERS', response.data)
      })
    },

    async getDisplays ({ commit }) {
      return httpCommon.get('data/displays/').then(response => {
        commit('SET_DISPLAYS', response.data)
      })
    },

  },
}
