import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/account/Index'),
      children: [
        {
          name: 'Home',
          path: '/',
          component: () => import('@/views/account/Login'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/account/Login'),
          meta: { guest: true },
        },
      ],
    },
    {
      path: '/admin/',
      component: () => import('@/views/dashboard/Index'),
      meta: { requiresAuth: true },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Devices',
          path: 'devices',
          component: () => import('@/views/dashboard/Devices'),
        },
        {
          name: 'Users',
          path: 'users',
          component: () => import('@/views/dashboard/Users'),
        },

        // {
        //   name: 'Doors',
        //   path: 'doors',
        //   component: () => import('@/views/dashboard/Doors'),
        // },
        // {
        //   name: 'AllDoors',
        //   path: 'doors/:locker_id',
        //   component: () => import('@/views/dashboard/Doors'),
        // },

        // {
        //   name: 'Webhooks',
        //   path: 'webhooks',
        //   component: () => import('@/views/dashboard/Webhooks'),
        // },

        // {
        //   name: 'Display Settings',
        //   path: 'display',
        //   component: () => import('@/views/dashboard/Display'),
        // },
        {
          name: 'Logs',
          path: 'logs',
          component: () => import('@/views/dashboard/Logs'),
        },
{
          name: 'Reports',
          path: 'reports',
          component: () => import('@/views/dashboard/Reports'),
        },

        // {
        //   name: 'Reservations',
        //   path: 'reservations',
        //   component: () => import('@/views/dashboard/Reservations'),
        // },
        // {
        //   name: 'Projects',
        //   path: 'projects',
        //   component: () => import('@/views/dashboard/Projects'),
        // },

      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
