<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style>

.swal2-popup {
  font-family: "Roboto", sans-serif !important;
}

.blur {
  color: transparent;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.blur:hover {
  color: #000;
  font-weight: 400;
  text-shadow: 0 0 0;
}

.card-outter {
  padding-bottom: 50px;
}

.card-actions {
  position: absolute;
  bottom: 0;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

thead th span {
  color: #fff;
  font-size: 12px;
}

.v-data-footer {
  background-color: rgb(250, 250, 250);
  color: #fff;
}

.v-data-table-header {
  background-color: #4caf50;
  color: white;
}

.v-card__actions {
  background-color: #4caf50;
}

</style>
